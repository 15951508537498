function submitter(body) {
    const bodyname = body.value.name;
    const bodyemail = body.value.email;
    const bodymessage = body.value.message;

    form = document.querySelector('form');
    form.addEventListener('submit', (event) => {
        event.preventDefault();

        var { name, email, message } = event.target;

        name = bodyname;
        email = bodyemail;
        message = bodymessage;

        // console.log('Name: ', name);
        // console.log('Email: ', email);
        // console.log('Message: ', message);

        // prevent the form submit from refreshing the page
        //     event.preventDefault();

        //     const { name, email, message } = event.target;

        //     // Use your API endpoint URL you copied from the previous step
        const endpoint =
            "https://feguru.dandre.hu/api/sendmail";
        // We use JSON.stringify here so the data can be sent as a string via HTTP
        const body = JSON.stringify({
            name: name,
            email: email,
            message: message
        });
        const requestOptions = {
            method: "POST",
            body
        };

        fetch(endpoint, requestOptions)
            .then((response) => {
                if (!response.ok) throw new Error("Error in fetch");
                return response.json();
            })
            .then((response) => {
                // document.getElementById("result-text").innerText =
                //     "{{'contacts.result.positive'}}";
                alert('Successful! / Sikerült!');
            })
            .catch((error) => {
                // document.getElementById("result-text").innerText =
                //     "{{'contacts.result.negative'}}";
                alert('Failed! / Nem Sikerült!');
            });
    });
}
