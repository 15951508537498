<h1>
    {{'contacts.title' | translate}}
</h1>
<h3>
    {{'contacts.phone' | translate}}
</h3>
<h3>
    {{'contacts.mail' | translate}}
</h3>
<form [formGroup]="angForm" novalidate>
    <div class="form-group">
        <label>{{'contacts.name' | translate}}</label><br />
        <input class="form-control" formControlName="name" type="text">
    </div>
    <div *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)" class="alert alert-danger">
        <div *ngIf="angForm.controls['name'].errors.required">
            {{'contacts.name.req' | translate}}
        </div>
    </div>
    <div class="form-group">
        <label>{{'contacts.email' | translate}}</label><br />
        <input class="form-control" formControlName="email" type="text">
    </div>
    <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)" class="alert alert-danger">
        <div *ngIf="angForm.controls['email'].errors.required">
            {{'contacts.email.req' | translate}}
        </div>
    </div>
    <div class="form-group">
        <label>{{'contacts.message' | translate}}</label><br />
        <textarea class="form-control-message" id="message" formControlName="message"></textarea>
    </div>
    <button type="submit " (click)="createSubmitJs()" [disabled]="angForm.pristine || angForm.invalid " class="btn btn-success ">
            {{'contacts.submit' | translate}}
        </button>
    <div>
        <p id="result-text"></p>
    </div>
</form>
