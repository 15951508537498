import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  toggleMenu = true;

  constructor(private router: Router) {
  }

  navigateToAbout() {
    this.router.navigate(['']);
  }

  togg() {
    return this.toggleMenu = !this.toggleMenu;
  }
}
