<h1>{{'about.title' | translate}}</h1>
<h2>{{'about.text1' | translate}}</h2>
<h2>{{'about.text2' | translate}}</h2>
<h2>{{'about.text3' | translate}}</h2>
<h2>{{'about.text4' | translate}}</h2>
<!-- <h3 (click)="getSource1()" style="cursor: pointer">{{'about.cv.link' | translate}}</h3> -->
<h2>{{'about.recommend' | translate}}</h2>
<div id="box">
<h3 (click)="getSource2()" style="cursor: pointer">{{'about.hcso.link' | translate}}</h3>
<h3 (click)="getSource3()" style="cursor: pointer">{{'about.ibuild.link' | translate}}</h3>
</div>
<h2>---***---</h2>
<h2>{{'about.donate1' | translate}}</h2>
<h2>{{'about.donate2' | translate}}</h2>
<div id="box">
<h4>{{'about.donate3' | translate}}</h4>
<h4>{{'about.donate4' | translate}}</h4>
<h4>{{'about.donate5' | translate}}</h4>
</div>
<h2>{{'about.donate6' | translate}}</h2>