import {Component} from '@angular/core';
import {PdfViewerService} from '../pdf-viewer.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent {

  constructor(
    private pdfService: PdfViewerService,
    private translate: TranslateService) {
    }

  // pdfSource1 = 'assets/cv_it_en_n.pdf';
  pdfSource2 = 'assets/HCSO_Confirmation_en.pdf';
  pdfSource3 = 'assets/IBUILD_Confirmation_en.pdf';

  // getSource1() {
  //   if (this.translate.currentLang === 'hu') {
  //     this.pdfSource1 = 'assets/cv_it_hu_n.pdf';
  //   } else {
  //     this.pdfSource1 = 'assets/cv_it_en_n.pdf';
  //   }
  //   this.openPDF(this.pdfSource1);
  // }

  getSource2() {
    if (this.translate.currentLang === 'hu') {
      this.pdfSource2 = 'assets/HCSO_Confirmation_hu.pdf';
    } else {
      this.pdfSource2 = 'assets/HCSO_Confirmation_en.pdf';
    }
    this.openPDF(this.pdfSource2);
  }

  getSource3() {
    if (this.translate.currentLang === 'hu') {
      this.pdfSource3 = 'assets/IBUILD_Confirmation_hu.pdf';
    } else {
      this.pdfSource3 = 'assets/IBUILD_Confirmation_en.pdf';
    }
    this.openPDF(this.pdfSource3);
  }

  openPDF(source) {
    window.open(this.pdfService.fetchPDF(source), '_blank');
  }

}
