import {Component} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  openMovie() {
    window.open('https://youtube.com/playlist?list=PLKaEtky-niB7GZb6ccpiPP2ll511JrDI4', '_blank');
  }

}
