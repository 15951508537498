import {Injectable} from '@angular/core';
import {
BehaviorSubject,
Observable
} from 'rxjs';
import {
AppEvent,
AppEventType
} from './../event.model';
import {EventBusService} from './../event-bus.service';

@Injectable({providedIn: 'root'})
export class SpinnerService {

  private sources: object[] = [];

  private loadingSubject = new BehaviorSubject(false);
  private _loadingState$ = this.loadingSubject.asObservable();

  constructor(private eventBus: EventBusService) {
    this.eventBus.on(AppEventType.LOADING, ((event: AppEvent) => this.onLoadingEvent(event)));
  }

  get loadingState$(): Observable<boolean> {
    return this._loadingState$;
  }

  private onLoadingEvent(event: AppEvent) {
    if (event.value) {
      this.start(event.source);
    } else {
      this.stop(event.source);
    }
  }

  private start(source: object) {
    this.sources.push(source);
    this.loadingSubject.next(true);
  }

  private stop(source: object) {
    const idx = this.sources.indexOf(source);
    if (idx >= 0) {
      this.sources.splice(idx, 1);
    }
    this.loadingSubject.next(this.sources.length > 0);
  }
}
