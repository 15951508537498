import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import {map} from 'rxjs/operators';
import {SpinnerService} from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

  private _hidden$ = this.spinnerService.loadingState$.pipe(
    map(loading => !loading)
  );

  constructor(private spinnerService: SpinnerService) {
  }

  get hidden$() {
    return this._hidden$;
  }

}
