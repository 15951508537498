import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RedirectGuard {
	constructor(private router: Router) {}
	canActivate(snapshot) {
		this.router.navigateByUrl(snapshot.redirectTo, {skipLocationChange: true});
	}
}
