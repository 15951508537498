import {Component} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {slideInAnimation} from './animations';
import {EventBusService} from './event-bus.service';
import {AppEvent, AppEventType} from './event.model';
import {SpinnerService} from './spinner/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent {
  title = 'imre novak freelance application developer, musician and media artist';

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private eventBus: EventBusService,
    private translate: TranslateService
  ) {
    this.translate.addLangs(['en', 'hu']);
    this.translate.setDefaultLang('en');
    this.router.events.subscribe((event: any) => {
      return this.onRouterEvent(event);
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  private onRouterEvent(event: Event) {
    if (event instanceof NavigationStart) {
      this.eventBus.emit(new AppEvent(this, AppEventType.LOADING, true));
    }

    if (event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel) {
      this.eventBus.emit(new AppEvent(this, AppEventType.LOADING, false));
    }
  }
}
