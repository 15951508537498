import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {AppEvent, AppEventType} from './event.model';

@Injectable({providedIn: 'root'})
export class EventBusService {

  private events = new Subject<AppEvent>();

  on(type: AppEventType, action: any): Subscription {
    return this.events.pipe(
      filter((event: AppEvent) => {
        return event.type === type;
      })
    ).subscribe(action);
  }

  emit(event: AppEvent): void {
    this.events.next(event);
  }
}
