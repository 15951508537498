import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {RedirectGuard} from './redirectGuard';

import {AppComponent} from './app.component';
import {AboutMeComponent} from './about-me/about-me.component';
import {ReferencesComponent} from './references/references.component';
import {ContactsComponent} from './contacts/contacts.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {HomeComponent} from './home/home.component';
import {PdfViewerService} from './pdf-viewer.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    ReferencesComponent,
    ContactsComponent,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      // {
      //   path: '404',
      //   component: PageNotFoundComponent
      // },
      {path: 'app-about-me', component: AboutMeComponent, data: {animation: 'AboutMe'}},
      {path: 'app-references', component: ReferencesComponent, data: {animation: 'References'}},
      {path: 'app-contacts', component: ContactsComponent, data: {animation: 'Contacts'}},
      {path: 'app-home', component: HomeComponent, data: {animation: 'Home'}},
      {
        path: '',
        canActivate: [RedirectGuard],
        redirectTo: '/app-home',
        pathMatch: 'full'
      },
      {
        path: '**',
        canActivate: [RedirectGuard],
        redirectTo: '/app-home',
        pathMatch: 'full'
      }
    ]),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    PdfViewerService,
    TranslateStore
  ],
  exports: [
    RouterModule,
    TranslateModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
