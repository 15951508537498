import {Component} from '@angular/core';
import {PdfViewerService} from '../pdf-viewer.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  buttonLanguage = 'hu';

  constructor(
    private pdfService: PdfViewerService,
    private translate: TranslateService) {
  }

  pdfSource1 = 'assets/cv_it_en_n.pdf';

  openPDF(source) {
    window.open(this.pdfService.fetchPDF(source), '_blank');
  }

  get currentYear(): string {
    return '' + new Date().getFullYear();
  }

  goFace() {
    window.location.href = 'https://m.facebook.com/imre.novak.9';
  }

  goLinked() {
    window.location.href = 'https://www.linkedin.com/in/imre-novak-097b296a';
  }

  goGitHub() {
    window.location.href = 'https://github.com/imre972/';
  }

  setButtonLang(): string {
    if (this.translate.currentLang === 'hu') {
      this.pdfSource1 = 'assets/cv_it_hu_n.pdf';
      return 'en';
    } else {
      this.pdfSource1 = 'assets/cv_it_en_n.pdf';
      return 'hu';
    }
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    this.buttonLanguage = this.setButtonLang();
  }
}
