<div class="header-bg">
    <!-- <div class="container"> -->
    <div class="header">
        <div class="header-left">
            <img skipLocationChange src="/assets/my_head.png" alt="" class="header-logo" [routerLink]="[ 'app-home'] ">
        </div>
        <ul class="header-right">
            <li skipLocationChange class="head-link " [routerLink]="[ 'app-about-me'] " [routerLinkActive]="[ 'head-link-active'] ">
                {{'header.about' | translate}}</li>
            <li skipLocationChange class="head-link " [routerLink]="[ 'app-references'] " [routerLinkActive]="[ 'head-link-active'] ">
                {{'header.references' | translate}}</li>
            <li skipLocationChange class="head-link " [routerLink]="[ 'app-contacts'] " [routerLinkActive]="[ 'head-link-active'] ">
                {{'header.contacts' | translate}}</li>
            <div class="header-divider "></div>
            <li class="head-link header-icon header-menu-icon "><span class="icon-menu " (click)="togg()"></span></li>
        </ul>
        <div class="mobile-head " *ngIf="!toggleMenu">
            <div class=" mobile-head-dropdown ">
                <div class="mobile-head-item mobile-head-drop-button " *ngIf="!toggleMenu" (click)="togg()">{{'header.menu' | translate}}<span class="icon-chevron_right "></span></div>
                <div class="mobile-head-drop-items ">
                    <div skipLocationChange class="mobile-head-drop-item " [routerLink]="[ 'app-about-me'] " (click)="togg()">{{'header.about' | translate}}</div>
                    <div skipLocationChange class="mobile-head-drop-item " [routerLink]="[ 'app-references'] " (click)="togg()">{{'header.references' | translate}}</div>
                    <div skipLocationChange class="mobile-head-drop-item " [routerLink]="[ 'app-contacts'] " (click)="togg()">{{'header.contacts' | translate}}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>
