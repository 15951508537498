<h1>{{'references.title' | translate}}</h1>
<h2>{{'references.subtitle1' | translate}}</h2>
<h3>{{'references.ap.4' | translate}}<a href="https://www.danubiainvestment.hu/" target="_blank">SITE</a></h3>
<h3>{{'references.ap.3' | translate}}<a href="https://www.alfa.hu/" target="_blank">SITE</a></h3>
<h3>{{'references.ap.2' | translate}}<a href="https://www.ibuild.info/" target="_blank">SITE</a></h3>
<h3>{{'references.ap.1' | translate}}<a href="https://youtu.be/guGVQUAFO8E" target="_blank">VIDEO</a></h3>
<h2>{{'references.subtitle2' | translate}}</h2>
<h3>{{'references.mu.4' | translate}}<a href="https://helyierzes.bandcamp.com/album/lemeztelen-t-s" target="_blank">MUSIC</a></h3>
<h3>{{'references.mu.3' | translate}}<a href="https://youtu.be/1PyEqmPHKig" target="_blank">VIDEO</a></h3>
<h3>{{'references.mu.2' | translate}}<a href="https://youtu.be/srE11TUZX8o" target="_blank">VIDEO</a></h3>
<h3>{{'references.mu.5' | translate}}<a href="https://youtu.be/ML9tRHjT6bY" target="_blank">VIDEO</a></h3>
<h3>{{'references.mu.1' | translate}}<a href="https://youtu.be/DQHWJLlsjlA" target="_blank">VIDEO</a></h3>
<h2>{{'references.subtitle3' | translate}}</h2>
<h3>{{'references.me.2' | translate}}<a href="https://youtu.be/VmC8nAsh8Mg" target="_blank">VIDEO</a></h3>
<h3>{{'references.me.1' | translate}}<a href="https://youtu.be/CWUFq6jSB0I" target="_blank">VIDEO</a></h3>