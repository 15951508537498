export enum AppEventType {
  HTTP_ERROR = 'HTTP_ERROR',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  LOG = 'LOG'
}

export class AppEvent {

  constructor(private _source: object, private _type: AppEventType, private _value: any) {
  }

  get source(): object {
    return this._source;
  }

  get type(): AppEventType {
    return this._type;
  }

  get value(): any {
    return this._value;
  }
}
