<footer>
    <div class="footer-main">
        <ul class="footer-nav">
            <li class="footer-nav-title">© 2021-{{currentYear}}. {{'footer.reserved' | translate}}</li>
            <button (click)="useLanguage(buttonLanguage)">{{'footer.translate' | translate}}</button>
            <li (click)="openPDF(pdfSource1)" class="footer-nav-link">CV</li>
            <li (click)="goFace()" class="footer-nav-link">facebook</li>
            <li (click)="goLinked()" class=" footer-nav-link ">linkedIn</li>
            <li (click)="goGitHub()" class=" footer-nav-link ">GitHub</li>
        </ul>
    </div>
</footer>
